import React from "react"

import Section1 from "../components/events/sections"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/resources-banner-bg.jpg"

const EventsPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Events" />
        <InnerBanner theme="white" image={banner} title="Events" />

         <Section1/>


        <HowToHelp />
    </Layout>
}

export default EventsPage