import React, {
  useEffect,
  useState,
} from "react"

import axios from "axios"
import { Link } from "gatsby"

import { API_BASE_URL } from "../../constants/config"
import { defaultDog } from "../../constants/defaults"
import * as styles from "./events.module.css"

const Section1 = () => {

    
    const [state, setState] = useState({ data: [], selected_data: null, total_page: 0, page: 1, busy: false });
    const [result, setResult] = useState([]);
    useEffect(() => {
        var m = true;
        if (m) {
            var config = {};
            config.params = { page: state.page, per_page:20 };
            setState(state => ({ ...state, busy: true }))
            axios.get(API_BASE_URL + "/website/events/list", config).then(({ data }) => {
    

                setState((state) => ({ ...state, data: data.data.data, total_page: data.data.total_page, busy: false }));
            })
        }
        return () => {
            m = false;
        }
    }, [ state.page])


    useEffect(() => {

        var new_data = state.data;
        setResult((state) => ([...state, ...new_data]));

    }, [state.data])
    return <div className={styles.events}>
        <div className={styles.events_content}>
           
            <div className={styles.events_content_items}>
                {result && result.map((item, key) => {
                    return <div className={styles.events_content_items_item} key={key}>
                        <div className={styles.events_content_items_item_thumb}>

                            <Link to={"/events/"+item.key}><img src={item.featured_image ? API_BASE_URL.replace("/v1", "/") + item.featured_image.path : defaultDog} alt={item.title} /></Link>
                        </div>
                        <div className={styles.events_content_items_item_content+" site-content"}>
                            <h4><Link to={"/events/"+item.key}>{item.title}</Link></h4>
                            <div className={styles.intro} dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                            <Link className={styles.events_content_items_item_readmore} to={"/events/"+item.key}>Read More</Link>
                        </div>
                    </div>
                })}
            </div>
            {state.page < state.total_page && <button className={"btn-accent mt-5 "+styles.btn_more} onClick={() => {
                    var n = state.page;
                    if (n < state.total_page) {
                        n++;
                        setState((state) => ({ ...state, page: n }))
                    }
                }}>More</button>}
        </div>
    </div>
}

export default Section1;